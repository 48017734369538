import numeral from "numeral"

export const initNumeralCzech = () => {
  if (numeral.locale() === "cs") {
    return
  }

  numeral.register("locale", "cs", {
    delimiters: {
      thousands: " ",
      decimal: ","
    },
    abbreviations: {
      thousand: "tis.",
      million: "mil.",
      billion: "mld.",
      trillion: "bln."
    },
    ordinal: function () {
      return "."
    },
    currency: {
      symbol: "Kč"
    }
  })

  numeral.locale("cs")
}
