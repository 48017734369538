import slugify from "slugify"
import seedrandom from "seedrandom"
import { CourseAgeGroup, CourseType } from "./Course"
import { PrismicDocument } from "@prismicio/types"
import { flatten } from "lodash"

export const getCourseDescriptionType = (ageGroup = CourseAgeGroup.adult, type = CourseType.inPerson): string => {
  let result = ""

  if (type === CourseType.online) {
    result = `online `
  }

  if (ageGroup === CourseAgeGroup.adult) {
    result = `${result}kurzy pro dospělé`
  } else {
    result = `${result}kurzy pro děti`
  }

  return result
}

export const getCourseTypeUrl = (ageGroup = CourseAgeGroup.adult): string => (ageGroup === CourseAgeGroup.adult ? "/kurzy-pro-dospele" : "/krouzky-pro-deti")

export const slugifyCourse = (d: PrismicDocument<any>, withId?: boolean) => {
  const slugify_name = slugify(d.data.title[0] && d.data.title[0].text, {
    replacement: "-",
    remove: /[/&,+()$#~%.'":*?!<>{}]/g,
    lower: true
  })
  const number_id = Math.round(seedrandom(`${slugify_name}${d.first_publication_date}`)().toFixed(6) * 1000000)
  const slug = `${slugify_name}-d${number_id}${typeof withId === "undefined" || withId === true ? `-${d.id}` : ""}`
  const oldSlug = d.slugs?.[0]

  return {
    slugify_name,
    number_id,
    slug,
    oldSlug
  }
}

export const slugifyTechnology = (d?: PrismicDocument<any>) => {
  if (!d?.data) {
    return {
      slugifyName: undefined,
      numberId: undefined,
      slug: undefined
    }
  }

  const slugifyName = slugify(d.data?.name && d.data?.name[0] && d.data?.name?.[0]?.text, {
    replacement: "-",
    remove: /[/&,+()$#~%.'":*?!<>{}]/g,
    lower: true
  })
  const numberId = Math.round(seedrandom(`${slugifyName}${d.first_publication_date}`)().toFixed(6) * 1000000)
  const slug = `${slugifyName}-d${numberId}`

  return {
    slugifyName,
    numberId,
    slug
  }
}

export const getParamsFromSlug = (slug: string) => {
  // demo https://regex101.com/r/iWUk9H/1

  // old format projektovy-den-1-running-dinosaur-d78171
  const match = flatten([...slug.matchAll(/([a-z0-9-_/]+)-d(\d+)/g)])

  //new format projektovy-den-1-running-dinosaur-d78171-X_-PEBUAACcAH2yX
  const matchId = flatten([...slug.matchAll(/([a-z0-9-_]+)-d(\d+)-([a-zA-Z0-9-_]+)/g)])

  return {
    originalSlug: match[0],
    slugName: match[1],
    numberId: match[2],
    id: matchId?.[3]
  }
}
