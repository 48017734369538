import styled from "@emotion/styled"
import { Button, Typography } from "antd"
import Link from "next/link"
import React from "react"
import { theme as themeColorPalette } from "../../styles/styles"
import { sm } from "../../styles/mediaQuery"
import { MappedCourse } from "../../lib/prismic/MappedCourse"

export const SubCourseCard: React.FC<Props> = ({ subCourse }) => {
  if (!subCourse.isOpen) {
    return null
  }

  return (
    <SubCourseCardWrapper color={themeColorPalette.colors["main-color-blue"]}>
      <Title level={4}>S ukázkovou hodinou</Title>
      <Text style={paragraphStyle}>Přihlaš se na ukázkovou hodinu zdarma.</Text>

      <span>
        <Button>
          <Link href={subCourse.registerUrl}>Více informací</Link>
        </Button>
      </span>
    </SubCourseCardWrapper>
  )
}

interface Props {
  subCourse: MappedCourse
}

export const paragraphStyle = {
  fontSize: 12,
  color: "white"
}

const Title = styled(Typography.Title)`
  ${sm} {
    display: none;
  }
`

const Text = styled(Typography.Paragraph)``

const SubCourseCardWrapper = styled.div<{ color: string }>`
  padding: 10px 14px 14px 14px;
  background-color: ${(props: { color: string }) => props.color};
  display: flex;
  flex-direction: column;

  ${sm} {
    padding: 10px 14px 10px 14px;
  }

  h4.ant-typography {
    color: white;
  }

  span {
    align-self: flex-end;
  }

  ${sm} {
    div.ant-typography {
      margin-bottom: 0;
    }

    span {
      button {
        display: none;
      }
    }
  }
`
