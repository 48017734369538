import React from "react"
import { PrismicDocument } from "@prismicio/types"
import { CourseAgeGroup } from "./Course"
import { AuthorAndFeedbacks } from "./courses"
import { slugifyTechnology } from "./common"
import { CourseFeedback } from "./MappedCourse"
import { getCourseTechnology } from "./getCourseTechnology"

export const getCourseTypeUrl = (ageGroup = CourseAgeGroup.adult): string => (ageGroup === CourseAgeGroup.adult ? "/kurzy-pro-dospele" : "/krouzky-pro-deti")

export const getTechnologyUrl = (d: PrismicDocument<any>): string => {
  const { slugifyName } = slugifyTechnology(d)

  return `/technologie/${slugifyName}`
}

export const getTechnology = (d: PrismicDocument<any>) => getCourseTechnology(d)

export const getLecturer = (d: AuthorAndFeedbacks) => getCourseLecturer(d)

export const getFeedBacks = (d?: PrismicDocument<any>[]) => {
  return (d || []).map((f) => getFeedback(f))
}

export interface CourseTechnology {
  name?: string
  slug?: string
  description?: string
  content?: React.ReactNode
  color: string
}

export const getFeedback = (d: PrismicDocument<any>): CourseFeedback => ({
  description: d.data.description[0].text,
  imageUrl: d.data.image.url,
  name: (d.data.name[0] && d.data.name[0].text) || "",
  sex: d.data.sex,
  slug: d.uid || "",
  rate: d.data.rate,
  dateTime: d.data.forcedFirstPublicationDate || d.first_publication_date
})

export interface CourseLecturer {
  name: string
  email: string
  bio: React.ReactNode
  pictureUrl: string
  position: string
}

export const getCourseLecturer = (d: AuthorAndFeedbacks): CourseLecturer => {
  if (!d?.author?.data) {
    return {
      name: "",
      email: "",
      bio: "",
      pictureUrl: "https://images.prismic.io/slicemachine-blank/30d6602b-c832-4379-90ef-100d32c5e4c6_selfie.png?auto=compress,format",
      position: ""
    }
  }

  return {
    name: (d.author.data.name[0] && d.author.data.name[0].text) || "",
    email: (d.author.data.email[0] && d.author.data.email[0].text) || "",
    bio: (d.author.data.bio[0] && d.author.data.bio[0].text) || "",
    pictureUrl: d.author.data.picture.url,
    position: (d.author.data.position[0] && d.author.data.position[0].text) || ""
  }
}
