import React from "react"
import { Button, Typography } from "antd"
import Link from "next/link"
import { css } from "@emotion/react"
import { md, sm, xl } from "../../styles/mediaQuery"
import Image from "next/legacy/image"
import { Theme } from "../../styles/theme"
import { theme as themeColorPalette } from "../../styles/styles"
import { ellipsis, paragraphStyle } from "./commonForCards"
import { Asset, Entry } from "contentful"
import { TypeProductEntitySkeleton } from "../../lib/contentful/ContentfulVzdelanibudoucnostiTypes"
import { getProductUrl } from "../../lib/contentful/product/getProductUrl"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"
import styled from "@emotion/styled"
import { ProductsColorsItem } from "../../lib/contentful/product/getAllProducts"
import { ShoppingCartOutlined } from "@ant-design/icons"
import { useCart } from "../Cart/useCart"
import { useRouter } from "next/router"

/*const getDescriptionType = (p: Entry<TypeProductEntitySkeleton, undefined, string>): string => {
  switch (p.fields.type) {
    case "onlineCourse":
      return "online kurz"
    case "inPersonCourse":
      return "kurz"
    case "inPersonEvent":
      return "událost"
    case "onlineEvent":
      return "online událost"
    default:
      return "produkt e-shopu"
  }
}

const isProductAvailable = ({ fields: { availableTo, availableFrom } }: Entry<TypeProductEntitySkeleton, undefined, string>): boolean => {
  return availableFrom && availableTo && dayjs().isAfter(dayjs(availableFrom)) && dayjs().isBefore(dayjs(availableTo))
}*/

export const ProductCard = ({
  product,
  simpleLayout = false,
  theme,
  informationOnly = false,
  whenOnHeader = false,
  dateAtHeader = false,
  productColors
}: ProductCardType) => {
  const router = useRouter()
  const { addToCart } = useCart()

  const {
    fields: { title, image, description, price }
  } = product

  const imageAsset = image ? (image as Asset<undefined, string>) : undefined
  const descriptionAsText = documentToPlainTextString(description)
  const url = `/eshop/produkt/${getProductUrl(product)}`

  const textColor = productColors?.readableColor ?? "#fff"
  const color = productColors?.mainColor ?? "rgba(0,0,0,0.85)"

  return (
    <>
      <CourseCardWrapper theme={theme}>
        <Link href={url} title={`Přejít na ${title}`}>
          <TopSpace simpleLayout={simpleLayout} height={whenOnHeader ? "175px" : undefined}>
            <div
              css={css`
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              `}>
              {imageAsset && <Image alt={imageAsset.fields.title} src={`https:${imageAsset.fields.file.url}`} layout="fill" objectFit="cover" />}
            </div>
          </TopSpace>
        </Link>

        <TitleText>
          <Typography.Title
            level={3}
            style={{ color: "rgba(0,0,0,0.85)", fontSize: whenOnHeader ? 14 : 15, lineHeight: whenOnHeader ? 1.2 : "normal", marginBottom: 0 }}>
            {title}
          </Typography.Title>
        </TitleText>

        {!simpleLayout && (
          <BottomSpace theme={theme}>
            <Typography.Paragraph style={paragraphStyle} ellipsis={ellipsis}>
              {descriptionAsText}
            </Typography.Paragraph>

            <FlexEnd
              css={css`
                ${sm} {
                  flex-direction: column;
                  gap: 8px;
                  align-items: flex-start;
                }
              `}>
              <Button
                onClick={() => {
                  addToCart({ id: product.sys.id, name: title, price, quantity: 1, url: router.asPath })
                  router.push(`/kosik`)
                }}
                style={{
                  backgroundColor: color,
                  borderColor: color,
                  color: textColor
                }}
                icon={<ShoppingCartOutlined />}
              />

              <Button
                type="primary"
                style={{
                  backgroundColor: color,
                  borderColor: color,
                  color: textColor
                }}>
                <Link href={url}>Více informací</Link>
              </Button>
            </FlexEnd>
          </BottomSpace>
        )}
      </CourseCardWrapper>
    </>
  )
}

interface ProductCardType {
  product: Entry<TypeProductEntitySkeleton, undefined, string>
  productColors: ProductsColorsItem
  simpleLayout?: boolean
  theme?: Theme
  informationOnly?: boolean
  whenOnHeader?: boolean
  withSubCourse?: boolean
  dateAtHeader?: boolean
}

const TitleText = styled.div`
  padding: 14px 14px 0 14px;
`

const CourseCardWrapper = styled.div<{ disabled?: boolean; isBeforeRegistration?: boolean; isAfterRegistration?: boolean; theme?: Theme }>`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  height: 100%;
  position: relative;
  background-color: white;

  ${(props: { disabled?: boolean; isBeforeRegistration?: boolean; isAfterRegistration?: boolean; theme?: Theme }) =>
    props.disabled || props.isBeforeRegistration || props.isAfterRegistration
      ? `
    pointer-events: none;
    filter: grayscale(1);
    opacity: 75%;
    
    &:before {
      content: "${props.isBeforeRegistration ? "Již brzy!" : ""}${props.isAfterRegistration ? "Již proběhlo." : ""}"
      position: absolute;
      transform: rotate(-45deg) translate(50%, 50%);
      top: 50%;
      left: 5%;
      color: #fff;
      z-index: 9;
      opacity: 50%;
      font-size: 50px;
      padding: 5px 14px 5px 14px;
      ${props.theme === Theme.dark ? `background-color: ${themeColorPalette.colors.dark.bgGray};` : `background-color: #000;`}
    }
  `
      : ""}

  ${md} {
    flex-direction: column;
    width: 100%;
  }
`

const TopSpace = styled.div<{ simpleLayout: boolean; height?: string }>`
  position: relative;
  ${(props: { simpleLayout: boolean }) => (props.simpleLayout ? `height: 145px;` : `height: 175px;`)}
  ${(props: { height?: string }) =>
    props.height
      ? `
    height: ${props.height};
    
    h3.ant-typography {
      margin-top: 0;
    }
  `
      : ``}
    width: 100%;

  ${xl} {
    min-width: 135px;
    width: initial;
  }

  ${md} {
    min-width: 135px;
    width: initial;
    height: 145px;
  }

  ${sm} {
    min-width: 125px;
    width: initial;
  }
`

const BottomSpace = styled.div<{ theme?: Theme }>`
  width: 100%;
  padding: 12px 14px 12px 14px;
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  place-content: space-between;

  ${md} {
    padding: 9px;
  }
`

export const FlexEnd = styled.span`
  display: flex;
  align-items: flex-end;
  margin-top: 9px;
  flex-direction: row;
  justify-content: space-between;
`
