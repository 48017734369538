import React from "react"
import { css } from "@emotion/react"
import { md } from "../../styles/mediaQuery"
import { Alert, Col, Row } from "antd"
import { CourseCard } from "../CourseCard/CourseCard"
import { getTechnology } from "../../lib/prismic/mapData"
import { PrismicDocument } from "@prismicio/types"
import { Theme } from "../../styles/theme"
import { chunk } from "lodash"
import { TheCarouselBatchItem } from "./TheCarouselBatchItem"
import { ColProps } from "antd/lib/col"
import { getCourse } from "../../lib/prismic/getCourse"
import { Entry } from "contentful"
import { TypeProductEntitySkeleton } from "../../lib/contentful/ContentfulVzdelanibudoucnostiTypes"
import { ProductCard } from "../CourseCard/ProductCard"
import { ProductsColorsItem } from "../../lib/contentful/product/getAllProducts"

export const CoursesList = ({
  courses,
  products,
  productsColors,
  simpleLayout = false,
  className,
  theme,
  informationOnly,
  whenOnHeader,
  withSubCourse,
  carouselWidth,
  colProps = { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
  dateAtHeader = false
}: Props) => {
  const productItems = (products ?? []).map((p) => (
    <ProductCard
      key={p.sys.id}
      product={p}
      productColors={productsColors.find((c) => c.id === p.sys.id)}
      simpleLayout={simpleLayout}
      theme={theme}
      informationOnly={informationOnly}
      whenOnHeader={whenOnHeader}
      withSubCourse={withSubCourse}
      dateAtHeader={dateAtHeader}
    />
  ))

  const carouselItems = (courses ?? []).map((d) => (
    <CourseCard
      key={d.id}
      course={getCourse(d)}
      technology={getTechnology(d)}
      simpleLayout={simpleLayout}
      theme={theme}
      informationOnly={informationOnly}
      whenOnHeader={whenOnHeader}
      withSubCourse={withSubCourse}
      dateAtHeader={dateAtHeader}
    />
  ))

  const items = [...productItems, ...carouselItems]

  if (!items || items.length === 0) {
    return <Alert message="V této sekci zatím nemáme vypsané žádné kurzy." type="warning" />
  }

  return (
    <>
      {items.length >= 2 &&
        !carouselWidth &&
        chunk(items, 6).map((chunkItems, index) => (
          <TheCarouselBatchItem key={`chunkItems-${index}`} carouselItems={chunkItems} simpleLayout={simpleLayout} className={className} />
        ))}

      <Row
        gutter={[
          { xs: 14, sm: 14, md: 14, lg: 14, xl: 30 },
          { xs: 14, sm: 14, md: 14, lg: 14, xl: 30 }
        ]}
        css={css`
          ${items.length >= 2 && !carouselWidth
            ? `
              ${md} {
                display: none;
              }            
          `
            : ""}
        `}
        className={className}>
        {items.map((item, i) => (
          <Col key={`Col-${i}`} {...colProps}>
            {item}
          </Col>
        ))}
      </Row>
    </>
  )
}

interface Props {
  courses?: PrismicDocument[]
  products?: Entry<TypeProductEntitySkeleton, undefined, string>[]
  productsColors?: ProductsColorsItem[]
  simpleLayout?: boolean
  className?: string
  theme?: Theme
  informationOnly?: boolean
  whenOnHeader?: boolean
  withSubCourse?: boolean
  carouselWidth?: boolean
  colProps?: ColProps
  dateAtHeader?: boolean
}
