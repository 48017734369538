import React, { useState } from "react"
import { Menu, Space } from "antd"
import { useRouter } from "next/router"
import Link from "next/link"
import styled from "@emotion/styled"
import { md, sm, xl, xlMin } from "../../styles/mediaQuery"
import { socialsMenuItems, staticMenuItems } from "../../lib/menu/staticMenuItems"
import { ItemType } from "antd/lib/menu/hooks/useItems"
import { slide as ReactBurgerMenu, State } from "react-burger-menu"
import { MenuOutlined } from "@ant-design/icons"
import { css } from "@emotion/react"

export const HeaderMenu = ({ menuItems }: Props) => {
  const [menuOpenState, setMenuOpenState] = useState(false)
  const { route } = useRouter()

  const getFirstRoute = (route: string) => route.split("/")[1] || undefined
  const firstRoute = getFirstRoute(route)
  const defaultSelectedKeys = firstRoute ? [firstRoute] : undefined
  const items = [...(menuItems || staticMenuItems || [])]

  const onClickMenuBurgerHandler = () => {
    setMenuOpenState(true)
  }

  const onStateChangeHandler = (state: State) => {
    setMenuOpenState(state.isOpen)
  }

  return (
    <MenuWrapper>
      <LogoWrapper>
        <Link href={"/"} title="Domů">
          <Logo />
        </Link>
      </LogoWrapper>

      <RightSideMenuWrapper>
        <Menu style={{ minWidth: 52, minHeight: 52, flex: "auto" }} theme="dark" mode="horizontal" selectedKeys={defaultSelectedKeys} items={items} />
      </RightSideMenuWrapper>

      <ReactBurgerMenu
        isOpen={menuOpenState}
        onStateChange={onStateChangeHandler}
        customBurgerIcon={
          <MenuOutlined
            onClick={onClickMenuBurgerHandler}
            css={css`
              svg {
                width: inherit;
                height: inherit;
                fill: white;
              }
            `}
          />
        }
        right
        width={200}>
        <Space>
          {[...items, ...socialsMenuItems]
            // @ts-ignore
            .flatMap((items) => [items, ...(items?.children?.length > 0 ? items.children : [])])
            .map((item) => {
              return (
                // @ts-ignore
                <ReactBurgerMenuItem key={item.key}>{item.label}</ReactBurgerMenuItem>
              )
            })}
        </Space>
      </ReactBurgerMenu>
    </MenuWrapper>
  )
}

interface Props {
  menuItems?: ItemType[]
}

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: stretch;

  /* main burger menu element */
  > div:not([class]) {
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    display: none;

    ${md} {
      display: flex;
    }

    ${sm} {
      width: 45px;
      height: 45px;
      margin: 0 10px 0 0;
    }
  }
`

const LogoWrapper = styled.div`
  width: 250px;
  flex: 0 250px;

  ${sm} {
    width: 125px;
    flex: 0 125px;
  }
`

const Logo = styled.div`
  background-image: url("/images/vzdelanibudoucnosti_2.svg");
  background-position: center center;
  background-repeat: no-repeat;
  height: 80px;
  width: 250px;
  display: block;

  ${sm} {
    width: 180px;
    height: 45px;
  }
`

const RightSideMenuWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  text-align-last: right;

  > ul {
    justify-content: flex-end;
  }

  > ul {
    width: 100%;
  }

  ${md} {
    display: none;
  }
`

const ReactBurgerMenuItem = styled.div`
  line-height: 1;
  padding: 18px 0 18px 0;
`
