import { theme } from "../../styles/styles"
import { htmlSerializer } from "./htmlSerializer"
import { CourseTechnology } from "./mapData"
import { PrismicDocument } from "@prismicio/types"
import { asText } from "@prismicio/helpers"
import { RichText } from "prismic-reactjs"

export const getCourseTechnology = (d: PrismicDocument<any>): CourseTechnology => {
  if (!d?.data?.technology?.data) {
    return {
      name: "",
      slug: "",
      color: theme.colors["main-color-blue"],
      content: ""
    }
  }

  return {
    name: d.data.technology.data.name[0].text,
    description: asText(d.data.technology.data.description),
    slug: d.data.technology.uid,
    color: theme.colors?.[d.data.technology.data?.color] || theme.colors["main-color-blue"],
    content: d.data?.technology?.data?.content ? <RichText render={d.data.technology.data.content} htmlSerializer={htmlSerializer} /> : null
  }
}
