import { useEffect } from "react"
import { useRouter } from "next/router"

const UTM_PARAMS_LOCAL_STORAGE_KEY = "utm_params"

export interface UtmParams {
  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
  utm_term?: string
  utm_content?: string
}

export const isEmpty = (value: string | undefined | null): boolean => {
  return value === null || value === undefined || value.trim() === ""
}

export const isAllEmpty = (params?: UtmParams): boolean => {
  if (!params) {
    return false
  }

  return isEmpty(params.utm_source) && isEmpty(params.utm_medium) && isEmpty(params.utm_campaign) && isEmpty(params.utm_term) && isEmpty(params.utm_content)
}

export const isAnySet = (params?: UtmParams): boolean => {
  if (!params) {
    return false
  }

  return (
    !isEmpty(params.utm_source) || !isEmpty(params.utm_medium) || !isEmpty(params.utm_campaign) || !isEmpty(params.utm_term) || !isEmpty(params.utm_content)
  )
}

export const useSaveUTMParams = () => {
  const router = useRouter()

  useEffect(() => {
    if (!localStorage) {
      return
    }

    const query = router.query
    const storedParams = getUTMParamsFromStorage()
    const utmParams: UtmParams = {
      utm_source: (query.utm_source as string) || undefined,
      utm_medium: (query.utm_medium as string) || undefined,
      utm_campaign: (query.utm_campaign as string) || undefined,
      utm_term: (query.utm_term as string) || undefined,
      utm_content: (query.utm_content as string) || undefined
    }

    if (isAllEmpty(storedParams) && isAnySet(utmParams)) {
      localStorage.setItem(UTM_PARAMS_LOCAL_STORAGE_KEY, JSON.stringify(utmParams))
    }
  }, [router.query])
}

export const getUTMParamsFromStorage = (): UtmParams => {
  if (!localStorage) {
    return {}
  }

  try {
    const storedUTMParams = localStorage.getItem(UTM_PARAMS_LOCAL_STORAGE_KEY)

    return storedUTMParams ? JSON.parse(storedUTMParams) : {}
  } catch (e) {
    console.error(e)

    return {}
  }
}

export const clearUtmParamsFromStorage = () => {
  if (!localStorage) {
    return
  }

  localStorage.removeItem(UTM_PARAMS_LOCAL_STORAGE_KEY)
}
