import { css } from "@emotion/react"
import { md } from "../../styles/mediaQuery"
import { TheCarousel } from "../TheCarousel/TheCarousel"
import { TheCarouselItem } from "../TheCarousel/TheCarouselItem"
import React from "react"
import { EmotionJSX } from "@emotion/react/types/jsx-namespace"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"

export const TheCarouselBatchItem = ({ className, simpleLayout, carouselItems }: Props) => (
  <TheCarousel
    css={css`
      display: none;

      ${carouselItems.length <= 2
        ? `
        .react-multi-carousel-list {
          .react-multi-carousel-track {
            transform: none !important;
          }  
        }
      `
        : ""}

      ${md} {
        display: block;
      }
    `}
    className={className}
    slidesToSlide={1}
    partialVisible
    arrows={true}
    showDots={false}
    ssr={true}
    infinite={false}
    autoPlay={false}
    swipeable={true}
    shouldResetAutoplay={false}
    customLeftArrow={<LeftOutlined css={customArrowsStyle("left")} />}
    customRightArrow={<RightOutlined css={customArrowsStyle("right")} />}
    responsive={{
      desktop: {
        breakpoint: { max: 3000, min: 768 },
        items: 2,
        partialVisibilityGutter: 150
      },
      tablet: {
        breakpoint: { max: 767, min: 575 },
        items: 2,
        partialVisibilityGutter: simpleLayout ? 60 : 150
      },
      mobile: {
        breakpoint: { max: 576, min: 319 },
        items: 2,
        partialVisibilityGutter: 15
      },
      smallMobile: {
        breakpoint: { max: 320, min: 0 },
        items: 2,
        partialVisibilityGutter: simpleLayout ? 10 : 15
      }
    }}>
    {carouselItems.map((item, i) => (
      <TheCarouselItem key={`TheCarouselItem-${i}`}>{item}</TheCarouselItem>
    ))}
  </TheCarousel>
)

interface Props {
  simpleLayout?: boolean
  className?: string
  carouselItems: EmotionJSX.Element[]
}

const customArrowsStyle = (direction: "left" | "right") => css`
  width: 42px;
  height: 42px;
  position: absolute !important;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.45);
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  ${direction}: 30px;
  cursor: pointer;
`
