import Script from "next/script"

export const WidgetWithAiBot = () => {
  return (
    <Script
      src="https://www.chatbase.co/embed.min.js"
      // @ts-ignore
      chatbotId="Z-hHvlP27QaDmfoJQW7it"
      domain="www.chatbase.co"
      onReady={() => {
        // @ts-ignore
        window.embeddedChatbotConfig = {
          chatbotId: "Z-hHvlP27QaDmfoJQW7it",
          domain: "www.chatbase.co"
        }
      }}
      defer
    />
  )
}
