import { CourseTechnology } from "../../lib/prismic/mapData"
import { Theme } from "../../styles/theme"
import { darken, desaturate, rgba } from "polished"
import { MappedCourse } from "../../lib/prismic/MappedCourse"
import styled from "@emotion/styled"
import { theme as themeColorPalette } from "../../styles/styles"
import { md, sm, xl } from "../../styles/mediaQuery"

export const getTopSpaceTextColorTheme = (technology: CourseTechnology, isOpen: boolean, theme?: Theme): string => {
  if (theme === Theme.dark) {
    return darken(0.2, technology.color)
  }

  if (!isOpen) {
    return desaturate(0.2, technology.color)
  }

  return technology.color
}

export const removePattern = (text: string): string => text.replace(/\(.*?\)/g, "").trim()

export interface CourseCardType {
  course: MappedCourse
  technology: CourseTechnology
  simpleLayout?: boolean
  theme?: Theme
  informationOnly?: boolean
  whenOnHeader?: boolean
  withSubCourse?: boolean
  dateAtHeader?: boolean
}

export const CourseCardWrapper = styled.div<{ disabled?: boolean; isBeforeRegistration?: boolean; isAfterRegistration?: boolean; theme?: Theme }>`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  height: 100%;
  position: relative;
  background-color: white;

  ${(props: { disabled?: boolean; isBeforeRegistration?: boolean; isAfterRegistration?: boolean; theme?: Theme }) =>
    props.disabled || props.isBeforeRegistration || props.isAfterRegistration
      ? `
    pointer-events: none;
    filter: grayscale(1);
    opacity: 75%;
    
    &:before {
      content: "${props.isBeforeRegistration ? "Již brzy!" : ""}${props.isAfterRegistration ? "Již proběhlo." : ""}"
      position: absolute;
      transform: rotate(-45deg) translate(50%, 50%);
      top: 50%;
      left: 5%;
      color: #fff;
      z-index: 9;
      opacity: 50%;
      font-size: 50px;
      padding: 5px 14px 5px 14px;
      ${props.theme === Theme.dark ? `background-color: ${themeColorPalette.colors.dark.bgGray};` : `background-color: #000;`}
    }
  `
      : ""}

  ${md} {
    flex-direction: column;
    width: 100%;
  }
`

export const TopSpace = styled.div<{ simpleLayout: boolean; height?: string }>`
  position: relative;
  ${(props: { simpleLayout: boolean }) => (props.simpleLayout ? `height: 145px;` : `height: 175px;`)}
  ${(props: { height?: string }) =>
    props.height
      ? `
    height: ${props.height};
    
    h3.ant-typography {
      margin-top: 0;
    }
  `
      : ``}
  width: 100%;

  ${xl} {
    min-width: 135px;
    width: initial;
  }

  ${md} {
    min-width: 135px;
    width: initial;
    height: 145px;
  }

  ${sm} {
    min-width: 125px;
    width: initial;
  }
`

export const TopSpaceText = styled.div<{ color?: string; bgOpacity?: number }>`
  ${(props: { color?: string; bgOpacity?: number }) => (props.bgOpacity && props.color ? `background-color: ${rgba(props.color, props.bgOpacity)};` : "")}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 12px 14px 12px 14px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  ${md} {
    padding: 9px;
  }

  &:hover {
    ${(props: { color?: string }) => (props.color ? `background-color: ${rgba(props.color, 0.9)};` : "")}
  }
`

export const BottomSpace = styled.div<{ theme?: Theme }>`
  width: 100%;
  padding: 14px;
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  place-content: space-between;
  ${(props: { theme?: Theme }) =>
    props.theme === Theme.dark
      ? `
    background-color: ${themeColorPalette.colors.dark.bgGray};
    
    .ant-typography, h3.ant-typography {
      color: ${themeColorPalette.colors.dark.textWhite};  
    }
  `
      : `background-color: #fff;`}

  ${md} {
    padding: 9px;
  }
`

export const FlexEnd = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 9px;
`

export const ellipsis = {
  rows: 5,
  symbol: "více",
  expandable: true
}

export const paragraphStyle = {
  fontSize: 12,
  marginBottom: 0
}

export const h4Style = {
  marginBottom: "0.25em"
}

export const grayScaleImageStyle = `
  img {
    filter: grayscale(100%);
  }
`

export const Flex = styled.div`
  ${sm} {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
`
