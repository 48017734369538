import { MappedCourse } from "./MappedCourse"
import { getCourseDescriptionType, slugifyCourse } from "./common"
import { createSeedCourseNumber } from "../bank/createSeedCourseNumber"
import { htmlSerializer } from "./htmlSerializer"
import { CourseAgeGroup, CourseType, CourseVariant } from "./Course"
import { isCourseRegistrationAfter, isCourseRegistrationBefore, isCourseRegistrationOpen } from "./conditions"
import { getCourseTypeUrl, getTechnologyUrl } from "./mapData"
import { PrismicDocument, FilledImageFieldImage } from "@prismicio/types"
import { RichText } from "prismic-reactjs"
import moment from "moment"
import { asText } from "@prismicio/helpers"
import { RTEmbedNode } from "@prismicio/types/src/value/richText"

const extractVideoUrl = (content: any[]) => {
  return content.filter((c) => c.type === "embed") as RTEmbedNode[]
}

const filterOutYoutubeEmbeddedVideos = (content: any[]) => {
  return content.filter((c) => !(c.type === "embed" && c.oembed?.type === "video"))
}

export const getCourse = (d: PrismicDocument<any>): MappedCourse => {
  const { slugify_name, number_id, slug } = slugifyCourse(d)

  return {
    id: d.id,
    slugifyName: slugify_name,
    numberId: number_id,
    slug,
    seedNumber: createSeedCourseNumber(d.id),
    name: (d.data.title[0] && d.data.title[0].text) || "",
    excerpt: d.data?.excerpt ? <RichText render={d.data.excerpt} htmlSerializer={htmlSerializer} /> : null,
    excerptAsText: (d.data.excerpt[0] && d.data.excerpt[0].text) || "",
    additionalInformation:
      d.data?.additional_information?.length > 0 ? <RichText render={d.data.additional_information} htmlSerializer={htmlSerializer} /> : null,
    content: d.data?.content ? <RichText render={filterOutYoutubeEmbeddedVideos(d.data.content)} htmlSerializer={htmlSerializer} /> : null,
    embeddedContentVideoUrls: d.data?.content ? extractVideoUrl(d.data.content) : undefined,
    rate: 4,
    difficulty: Number(d.data.difficulty),
    ageGroup: d.data.age_group && d.data.age_group !== "" ? d.data.age_group : CourseAgeGroup.children,
    type: d.data.type && d.data.type !== "" ? d.data.type : CourseType.online,
    descriptionType: getCourseDescriptionType(d.data.age_group as CourseAgeGroup, d.data.type as CourseType),
    courseVariant: d.data.course_type as CourseVariant,
    when: (d.data.when[0] && d.data.when[0].text) || "",
    where: (d.data.where[0] && d.data.where[0].text) || "",
    moreUrl: `${getCourseTypeUrl(d.data.age_group as CourseAgeGroup)}/${slug}`,
    registerUrl: `/registrace/${slug}`,
    typeUrl: getCourseTypeUrl(d.data.age_group as CourseAgeGroup),
    price: d.data.price,
    capacity: d.data.capacity,
    registrationOpenFrom: d.data.registration_open_from,
    registrationOpenTo: d.data.registration_open_to,
    firstCourseDay: d.data?.first_course_day ? moment(d.data?.first_course_day) : undefined,
    lastCourseDay: d.data?.last_course_day ? moment(d.data?.last_course_day) : undefined,
    isOpen: isCourseRegistrationOpen(d),
    isBeforeRegistration: isCourseRegistrationBefore(d),
    isAfterRegistration: isCourseRegistrationAfter(d),
    image: d.data.content.find((c) => c.type === "image") || undefined,
    equipmentPrice: d.data.equipment_price || undefined,
    equipmentInfo: d.data.equipment_info ? <RichText render={d.data.equipment_info} htmlSerializer={htmlSerializer} /> : undefined,
    embed: d.data?.embed,
    onlineMeetingDescription: d.data?.online_meeting_description ? (
      <RichText render={d.data.online_meeting_description} htmlSerializer={htmlSerializer} />
    ) : undefined,
    onlineMeetingDescriptionText: d.data?.online_meeting_description ? asText(d.data?.online_meeting_description) : undefined,
    onlineMeetingLink: d.data?.online_meeting_link?.url || undefined,
    tags: d?.tags || [],
    photoGallery: (d.data?.["photo_gallery"]?.data?.images || []).map((g) => g.image) as FilledImageFieldImage[],
    subCourse: d.data?.["sub_course"]?.data ? getCourse(d.data?.["sub_course"]) : undefined,
    technologyUrl: getTechnologyUrl(d.data.technology),
    sampleCourseVideoUrl: d.data?.sample_course_video_url?.url,
    highlighted: d.data?.highlighted
  }
}
