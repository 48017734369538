import React from "react"
import { BackTop } from "antd"
import { HeaderMenu } from "./HeaderMenu"
import { Footer } from "./Footer"
import { HeaderWithHeight } from "./HeaderWithHeight"
import { css } from "@emotion/react"
import { WidgetWithAiBot } from "../WidgetWithAiBot/WidgetWithAiBot"
import { useSaveUTMParams } from "../../lib/utmParams"
import { initNumeralCzech } from "../../lib/numeral/numeralCzech"

export const Layout = ({ children }: Props) => {
  useSaveUTMParams()

  initNumeralCzech()

  return (
    <>
      <HeaderWithHeight style={{ position: "absolute", zIndex: 1, width: "100%", top: 0 }}>
        <HeaderMenu />
      </HeaderWithHeight>

      <div>{children}</div>

      <WidgetWithAiBot />

      <Footer />

      <BackTop
        css={css`
          z-index: unset;
        `}
      />
    </>
  )
}

interface Props {
  children: React.ReactNode
}
